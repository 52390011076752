import React from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Stack } from "react-bootstrap";
import { Bucket } from "../../../../api/config/apiConfig";
import gifLoading from "../../../../imgs/giphy.gif";

const PrebidFormDownload = ({ formContent }) => {
  const { uuid } = useParams();

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return (
    <>
      <div className="mx-4">
        <div className="d-flex align-items-center justify-content-center w-100">
          <p className="ps-0 mb-0 head-top">
            {"Prebid Agreement For import of vehicle"}
          </p>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Issue Date:</b> <span>{`${formContent.issueDate}`}</span>
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Confidentiality Notice: </b>{" "}
              <span>{`${formContent.notice}`}</span>
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>CONTRACT PRICE: </b>{" "}
              <span>{`${formContent.contractPrice}`}</span>
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>DATE: </b> <span>{`${formatDate(formContent.date)}`}</span>
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Introduction: </b>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>
                {`This document outlines the PRE- Bidding Agreement for the Import
                of a Vehicle as per the contractual agreement established
                between the parties involved. This agreement, made at Islamabad
                on the date ${formatDate(formContent.date)}, is between:`}
              </span>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>First Party: </b>{" "}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>“Buyer”</b>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>{`${formContent.firstParty.name} (${formContent.firstParty.organization})
(Hereinafter referred to as "the 1st Party", which expression shall, where the context so
permits, include his heirs, executors, administrators, and assigns)`}</span>{" "}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>And</b>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Second Party:</b>{" "}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>
                “Shelozon Movers Packers & Logistics Pvt Ltd’’ (Hereinafter
                referred to as "the 2nd Party")
              </span>{" "}
            </p>
          </div>
        </div>
        <hr />
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Instructions to Bidders: </b>{" "}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>The 1st Party shall provide the following documents: </span>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>{formContent.instructions}</span>{" "}
            </p>
          </div>
        </div>

        <hr />
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>INCLUSIONS: </b>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>2nd Party: </b>{" "}
              <span>
                Is responsible for the following services and its costs are
                included in the Contract Price:
              </span>
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>{formContent.inclusions}</span>
            </p>
          </div>
        </div>

        <hr />
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>EXCLUSIONS: </b>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>{formContent.exclusions}</span>{" "}
            </p>
          </div>
        </div>
        <hr />
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Demurrage: </b> <span>{formContent.demurrage}</span>
            </p>
          </div>
        </div>
        <hr />
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>PAYMENT TERMS:</b>{" "}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>
                The payment for the import of the vehicle shall be made as
                follows:
              </span>
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>2. 50% Payment at the Time of Delivery:</b>
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>{formContent.paymentTerms}</span>
            </p>
          </div>
        </div>

        <hr />
        <div className="row mt-12">
          {/* Add 'mt-4' for margin top */}
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b></b>{" "}
            </p>
            <p className="p-word mb-0 text-start">
              <b>CANCELLATION POLICY:</b>{" "}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span className="ms-1">
                1. In case the Buyer tends to cancel the agreement after the
                purchase and during the time of issue of the Bill of Lading, the
                Buyer is bound to pay the total amount of Contract Price
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span className="ms-1">
                2. If buyer cancels After the Issuance of Bill of Lading and
                before delivery of Vehicle, the Buyer will pay 50% of remaining
                amount of Total Contract Price.
              </span>
            </p>
          </div>
        </div>
        <hr />
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>APPENDIX:</b>{" "}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span className="ms-1">
                The 1st Party (Buyer) shall review the following documents and
                ensure their completeness:
              </span>

              <b>Pictures of the Vehicle:</b>
              <span className="ms-1">
                Comprehensive photographs of the vehicle from various angles,
                including interior and exterior views. These pictures serve as
                visual documentation of the vehicle's condition at the time of
                booking.
              </span>
              <b>Auction Sheet of the Vehicle:</b>
              <span className="ms-1">
                The Auction Sheet provides detailed information about the
                vehicle's specifications and condition.
              </span>
            </p>
          </div>
        </div>
        <hr />
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b> Car Description:</b>{" "}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Make:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.carDescription?.make
                  ? formContent?.carDescription?.make
                  : "-"}
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Model:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.carDescription?.model
                  ? formContent?.carDescription?.model
                  : "-"}
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Specification:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.carDescription?.specification
                  ? formContent?.carDescription?.specification
                  : "-"}
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Mileage:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.carDescription?.mileage
                  ? formContent?.carDescription?.mileage
                  : "-"}
              </span>
            </p>
          </div>
        </div>

        <Row
          className="bg-white my-4 mx-0 p-3 py-4 mb-4"
          style={{ borderRadius: "10px" }}
        >
          <div className="d-flex flex-column w-100">
            {formContent?.carDescription.images?.map((image, index) => {
              return (
                <div key={index} className="mb-3">
                  <img
                    src={`${Bucket}${image}`}
                    placeholderSrc={gifLoading}
                    alt={`Image ${index}`}
                    style={{
                      width: "100%", // Full width
                      height: "auto", // Maintain aspect ratio
                      objectFit: "cover", // Optional: Ensures image covers the container
                      borderRadius: "8px", // Rounded corners (optional)
                    }}
                  />
                </div>
              );
            })}
          </div>
        </Row>

        <hr />

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b> Important Note:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.importantNote ? formContent?.importantNote : "-"}
              </span>
            </p>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>First Party(Buyer)</b>{" "}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Name:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.firstParty?.name
                  ? formContent?.firstParty?.name
                  : "-"}
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Email:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.firstParty?.email
                  ? formContent?.firstParty?.email
                  : "-"}
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>MOFA ID NO:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.firstParty?.mofaIdNo
                  ? formContent?.firstParty?.mofaIdNo
                  : "-"}
              </span>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Organization:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.firstParty?.organization
                  ? formContent?.firstParty?.organization
                  : "-"}
              </span>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>2nd Party</b>{" "}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Name:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.secondParty?.name
                  ? formContent?.secondParty?.name
                  : "-"}
              </span>
            </p>
          </div>
        </div>

        <Row className="mt-1">
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent.firstParty.sign != "" &&
                formContent.firstParty.sign != undefined && (
                  <img
                    src={`${Bucket}${formContent.firstParty.sign}`}
                    height={80}
                    width={200}
                  />
                )}
            </div>

            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"First Party Sign"}</b>
            </p>

            {""}
          </Col>

          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent.secondParty.sign?.trim()?.length == 0 ? (
                <div className="d-flex justify-content-center align-items-end"></div>
              ) : (
                <img
                  src={`${Bucket}${formContent.secondParty.sign}`}
                  height={80}
                  width={200}
                />
              )}
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Shelozon Movers Packers & Logistics Pvt Ltd"}</b>
            </p>

            {""}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PrebidFormDownload;
